import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, Input, Modal } from "reactstrap";
import { NovaSenha, Validar, SalvarSenha } from "../../../../service/login";
import { toast } from 'react-toastify';
import { Spinner } from "react-bootstrap";

const Senha = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [campo1, setCampo1] = useState("");
  const [campo2, setCampo2] = useState("");
  const [campo3, setCampo3] = useState("");
  const [campo4, setCampo4] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const SolicitaNovaSenha = async () => {
    setLoading(true);
    const body = {
      login: email,
    };
    await NovaSenha(body).then((response) => {
        if (response.status === 200) {
            setStep(2);
            setLoading(false);
        } else {
            toast.error(response.data.message);
            setLoading(false);
        }
    });
    };

    const ValidarCodigo = async () => {
        setLoading(true);
    const body = {
      email: email,
      token: campo1 + campo2 + campo3 + campo4,
    };
    await Validar(body).then((response) => {
        if (response.status === 200) {
            setStep(3);
            console.log(response.data.data.id);
            setId(response.data.data.id);
            setLoading(false);
        } else {
            
            toast.error(response.response.data.msg);
            setLoading(false);
        }
    }).catch((err) => {
        setLoading(false);
        
        toast.error(err.response.data.msg);
    });
    };

    const Salvar = async () => {
        setLoading(true);
    const body = {
        id: id,
        senha: senha,
        confirmar_senha: confirmarSenha,
    }
    await SalvarSenha(body).then((response) => {
        console.log(response.status);
        if (response.status === 201) {
            console.log(response);
            navigate("/");
        } else {
            setLoading(false);
            toast.error(response.response.data.msg);
        }
    }
    ).catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.msg);
    }
    );
    console.log(body);
}


  return (
    <>
    <Modal isOpen={loading} className="modal-dialog-centered">
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            fontSize: "20px",
            fontWeight: "bold",
        }}
        >
            <Spinner animation="border" variant="primary" />&nbsp; &nbsp;  Aguarde...
        </div>
        </Modal>
      <div
        className="container-fluid"
        style={{
          display: step === 1 ? "flex" : "none",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "60vh",
          width: "100vw",
          backgroundColor: "#f5f5f5",
        }}
      >
        <h3>Recuperação Senha</h3>
        <br />
        <Card
          style={{
            width: "30vw",
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <br />
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col sm="12">
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Digite CPF ou CNPJ"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm="12">
                <Button
                  onClick={() => SolicitaNovaSenha()}
                  style={{ backgroundColor: "#b496db", color: "white", left: "0", border: "none" }}
                  type="button"
                  className="buttonLink"
                >
                  Enviar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div
        className="container-fluid"
        style={{
          display: step === 2 ? "flex" : "none",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "60vh",
          width: "100vw",
          backgroundColor: "#f5f5f5",
        }}
      >
        <h3>Recuperação Senha</h3>
        <br />
        <Card
          style={{
            width: "30vw",
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <br />
            <Row
              style={{
                width: "70%",
              }}
            >
              <Col sm="3">
                <Input
                  type="text"
                  maxLength={1}
                  pattern="\d*"
                  className="form-control"
                  id="campo1"
                  value={campo1}
                  onChange={(e) => setCampo1(e.target.value)}
                />
              </Col>
              <Col sm="3">
                <Input
                  type="text"
                  maxLength={1}
                  pattern="\d*"
                  className="form-control"
                  id="campo2"
                  value={campo2}
                  onChange={(e) => setCampo2(e.target.value)}
                />
              </Col>
              <Col sm="3">
                <Input
                  type="text"
                  maxLength={1}
                  pattern="\d*"
                  className="form-control"
                  id="campo3"
                  value={campo3}
                  onChange={(e) => setCampo3(e.target.value)}
                />
              </Col>
              <Col sm="3">
                <Input
                  type="text"
                  maxLength={1}
                  pattern="\d*"
                  className="form-control"
                  id="campo4"
                  value={campo4}
                  onChange={(e) => setCampo4(e.target.value)}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm="12">
                <Button
                  style={{ backgroundColor: "#b496db", color: "white", left: "0" }}
                  block="true"
                  type="button"
                  className="buttonLink"
                  onClick={() => ValidarCodigo()}
                >
                  Confirmar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div
        className="container-fluid"
        style={{
          display: step === 3 ? "flex" : "none",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "60vh",
          width: "100vw",
          backgroundColor: "#f5f5f5",
        }}
      >
        <h3>Recuperação Senha</h3>
        <br />
        <Card
          style={{
            width: "30vw",
            height: "40vh",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardBody
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <br />
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col sm="12">
                <Input
                  type="password"
                  className="form-control"
                  id="senha"
                  placeholder="Digite a nova senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </Col>
            </Row>
            <br></br>
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col sm="12">
                <Input
                  type="password"
                  className="form-control"
                  id="confirmarSenha"
                  placeholder="Confirme a nova senha"
                  value={confirmarSenha}
                  onChange={(e) => setConfirmarSenha(e.target.value)}
                  style={{ borderColor: senha === confirmarSenha ? "green" : "red" }}
                />
              </Col>
            </Row>
            <br></br>
            
            <Row>
              <Col sm="12">
                <Button
                  enabled={senha === confirmarSenha}
                  style={{ backgroundColor: "#b496db", color: "white" }}
                  block="true"
                  type="button"
                  className="buttonLink"
                  onClick={() => senha === confirmarSenha ? Salvar() : toast.error("As senhas não conferem.")}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Senha;
