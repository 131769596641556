import React, { useState } from 'react';

const SejaCredenciado = () => {    

    return (
        <>
            
        </>
    )
}

export default SejaCredenciado