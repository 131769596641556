import api from '../api';

export const PaginadorEstado = async (body) => {
    return api
      .post(`estado/paginar`, body, {
        headers: {
          "Content-Type": "application/json"
        
        },
      })
      .then((response) => response)
      .catch((err) => err);
    };

export const PaginadorCidade = async (body) => {
    return api
      .post(`cidade/paginar`, body, {
        headers: {
          "Content-Type": "application/json"
        
        },
      })
      .then((response) => response)
      .catch((err) => err);
    };

export const PaginadorCredenciado = async (body) => {
    return api
      .post(`especialidade/paginate`, body, {
        headers: {
          "Content-Type": "application/json"
        
        },
      })
      .then((response) => response)
      .catch((err) => err);
    };

