/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Button, Col, Modal, ModalBody, Row } from "react-bootstrap";
import Stepper from "../../../../../components/stepper";
import { Input, Label, Spinner } from "reactstrap";
import { Paginador } from "../../../../../service/planos";
import {
  BuscaAgregado,
  Pagamento,
  SaveAgregado,
  SaveAssinatura,
} from "../../../../../service/assinaturas";
import axios from "axios";
import { toast } from "react-toastify";
import american from "../../../../../assets/images/logos/american.png";
import visa from "../../../../../assets/images/logos/visa.png";
import mastercard from "../../../../../assets/images/logos/master.png";
import { Save, Trash, Trash2, UserPlus, X } from "react-feather";

import SelectAsyncVendedor from "../../../../../components/asynselectvendedor";

const AssinaturaADM = ({ setOpenModal }) => {
  const [spinner, setSpinner] = useState(false);
  const [step, setStep] = useState(0);
  const [dependentes, setDependentes] = useState({
    primeiro_nome: "",
    sobrenome: "",
    data_nascimento: "",
    documento: "",
    rg: "",
    doc_frente: "",
    doc_verso: "",
    selfie: "",
    files: [],
  });
  const [checado, setChecado] = useState(false);
  const [planos, setPlanos] = useState([]);
  const [planoSelecionados, setPlanoSelecionados] = useState([]);
  const [numeroAssinatura, setNumeroAssinatura] = useState("");
  const [modalAgregado, setModalAgregado] = useState(false);
  const [modalAgregadoSelecionado, setModalAgregadoSelecionado] = useState(0);
  const [agregadoSelecionado, setAgregadoSelecionado] = useState("");
  const [listaResultAgregados, setListaResultAgregados] = useState([]);
  const [vendedor, setVendedor] = useState({});
  const [subagregadoTemp, setSubagregadoTemp] = useState({
    primeiro_nome: "",
    sobrenome: "",
    data_nascimento: "",
    documento: "",
    rg: "",
    doc_frente: "",
    doc_verso: "",
    selfie: "",
  });
  const [dadosCartao, setDadosCartao] = useState({
    numero: "",
    cvv: "",
    validade: "",
  });
  const [seguroSelecionado, setSeguroSelecionado] = useState({
    precoSeguro: 0,
    descricaoSeguro: "Nenhum seguro selecionado",
  });
  const [dadosUsuario, setDadosUsuario] = useState({
    confirma_senha: "",
    data_nascimento: "",
    ddd: "",
    email: "",
    confirma_email: "",
    reemail: "",
    primeiro_nome: "",
    senha: "",
    sobrenome: "",
    telefone: "",
    documento: "",
    rg: "",
    nacionalidade: "",
    estado_civil: "",
    profissao: "",
    cep: "",
    endereco: "",
    numero: 0,
    doc_frente: "",
    doc_verso: "",
    selfie: "",
    vendedor: "",
    data_contratacao: "",
    files: [],
  });

  const ConvertData = (data) => {
    //1987-08-07T00:00:00.000Z to 07/08/1987
    var data = new Date(data);
    var dia = data.getDate().toString().padStart(2, "0");
    var mes = (data.getMonth() + 1).toString().padStart(2, "0");
    var ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  };

  const ListarAgregados = () => {
    BuscaAgregado(numeroAssinatura)
      .then((response) => {
        console.log("RESPONSE", response);
        setListaResultAgregados(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [tooltipId, setTooltipId] = useState(null);

  const botoesPlanos = () => {
    Paginador()
      .then((response) => {
        setPlanos(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSaveAgregado = () => {
    setSpinner(true);
    var body = {};
    body.dependente = dependentes;
    body.id = numeroAssinatura;
    body.agregado_id = agregadoSelecionado;
    console.log("BODY AGREGADO", body);
    SaveAgregado(body)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          ListarAgregados();
          setSpinner(false);
          setDependentes({
            primeiro_nome: "",
            sobrenome: "",
            data_nascimento: "",
            documento: "",
            rg: "",
            doc_frente: "",
            doc_verso: "",
            selfie: "",
            files: [],
          });
          setModalAgregado(false);
          setAgregadoSelecionado("");
          window.scrollTo(0, 0);
        } else {
          toast.error(response.response.data.msg);
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log("perereca ERRO", error);
      });
  };

  useEffect(() => {
    botoesPlanos();
    if (numeroAssinatura !== "") {
      ListarAgregados();
    }
  }, []);

  const TabelaAgregados = () => {
    const [expandedRows, setExpandedRows] = useState([]);

    const handleRowClick = (index) => {
      const currentIndex = expandedRows.indexOf(index);
      const newExpandedRows = [...expandedRows];
      if (currentIndex === -1) {
        newExpandedRows.push(index);
      } else {
        newExpandedRows.splice(currentIndex, 1);
      }
      setExpandedRows(newExpandedRows);
    };

    return (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Documento</th>
              <th>Data de Nascimento</th>
              <th style={{display: checado === true ? "block" : "none"}}>Agregados</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {listaResultAgregados.map((item, index) => (
              <>
                <tr onClick={() => handleRowClick(index)}>
                  <td>
                    {item.usuario.primeiro_nome} {item.usuario.sobrenome}
                  </td>
                  <td>{item.usuario.documento}</td>
                  <td>{ConvertData(item.usuario.data_nascimento)}</td>
                  {expandedRows.includes(index) ? (
                    <td>
                      {item.dependentes !== undefined
                        ? item.dependentes.length
                        : 0}
                    </td>
                  ) : (
                    <td style={{display: checado === true ? "block" : "none"}}>
                      {item.dependentes !== undefined
                        ? item.dependentes.length
                        : 0}
                    </td>
                  )}
                  <td>
                    <UserPlus
                      style={{ cursor: "pointer", display: checado === true ? "block" : "none"}}
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalAgregado(true);
                        setAgregadoSelecionado(item.usuario.id);
                      }}
                      size={20}
                    />
                  </td>
                </tr>
                {expandedRows.includes(index) &&
                  item.dependentes &&
                  item.dependentes.map((dependente, depIndex) => (
                    <tr key={depIndex} style={{ backgroundColor: "#f0ebf7" }}>
                      <td colSpan="1">
                        {dependente.usuario.primeiro_nome}{" "}
                        {dependente.usuario.sobrenome}
                      </td>
                      <td colSpan="1">{dependente.usuario.documento}</td>
                      <td colSpan="1">
                        {ConvertData(dependente.usuario.data_nascimento)}
                      </td>
                      <td colSpan="1"></td>
                      <td colSpan="1">
                        <Trash2
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   setModalAgregado(true);
                          //   setAgregadoSelecionado(dependente.usuario.id);
                          // }}
                          size={20}
                        />
                      </td>
                    </tr>
                  ))}
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const bandeira = (bandeira) => {
    var primeirodigito = bandeira.substring(0, 1);
    switch (primeirodigito) {
      case "3":
        return <img src={american}></img>;
      case "4":
        return <img src={visa}></img>;
      case "5":
        return <img src={mastercard}></img>;
      default:
        return null;
    }
  };

  const idadeAtual = (data) => {
    var dataNascimento = new Date(data);
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascimento = dataNascimento.getFullYear();
    var idade = anoAtual - anoNascimento;
    var mesAtual = dataAtual.getMonth();
    var mesNascimento = dataNascimento.getMonth();
    if (mesAtual < mesNascimento) {
      idade--;
    } else if (mesAtual === mesNascimento) {
      var diaAtual = dataAtual.getDate();
      var diaNascimento = dataNascimento.getDate();
      if (diaAtual < diaNascimento) {
        idade--;
      }
    }
    return idade;
  };

  const NomeBandeira = (cartao) => {
    var primeirodigito = cartao.substring(0, 1);
    switch (primeirodigito) {
      case "3":
        return "American Express";
      case "4":
        return "Visa";
      case "5":
        return "Mastercard";
      default:
        return null;
    }
  };

  const Pagar = () => {
    setSpinner(true);
    const body = {
      assinatura: numeroAssinatura,
      cvv: dadosCartao.cvv,
      titular: dadosUsuario.primeiro_nome + " " + dadosUsuario.sobrenome,
      numero_cartao: dadosCartao.numero,
      data_vencimento: dadosCartao.validade,
      bandeira: NomeBandeira(dadosCartao.numero),
      valor:
        planoSelecionados.preco != null
          ? planoSelecionados.preco.toFixed(2).replace(".", ",")
          : "0,00",
    };

    Pagamento(body)
      .then((response) => {
        console.log("BODE PAGAMENTO", body);
        console.log("PAGAMENTO", response);
        if (response.status === 201) {
          toast.success("Pagamento realizado com sucesso!");
          setSpinner(false);
          setStep(5);
          window.scrollTo(0, 0);
        } else {
          toast.error(response.response.data.msg);
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log("PAGAMENTO", error);
      });
  };

  const handleFileDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result; // Obter apenas a string base64
      let newArray = [...dadosUsuario.files];
      newArray.push({ name: file.name, base64: base64String });
      setDadosUsuario({ ...dadosUsuario, files: newArray });
    };

    reader.readAsDataURL(file);
  };

  const handleFileDropDependente = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result; // Obter apenas a string base64
      let newArray = [...dependentes.files];
      newArray.push({ name: file.name, base64: base64String });
      setDependentes({ ...dependentes, files: newArray });
    };

    reader.readAsDataURL(file);
  };

  const removeFile = (index) => {
    let newArray = [...dadosUsuario.files];
    newArray.splice(index, 1);
    setDadosUsuario({ ...dadosUsuario, files: newArray });
  };

  const removeFileDependentes = (index) => {
    let newArray = [...dependentes.files];
    newArray.splice(index, 1);
    setDependentes({ ...dependentes, files: newArray });
  };

  const handleInputChange = (event) => {
    const inputValue = event;
    const digitsOnly = inputValue.replace(/\D/g, ""); // Remove caracteres não numéricos
    const formatted = digitsOnly.slice(0, 16); // Limita a 16 dígitos
    const formattedWithSpaces = formatted.replace(/(\d{4})(?=\d)/g, "$1 "); // Adiciona espaço a cada 4 dígitos
    // Adiciona espaço a cada 4 dígitos

    setDadosCartao({ ...dadosCartao, numero: formattedWithSpaces });
  };

  const handleInputChangeCVV = (event) => {
    const inputValue = event;
    const digitsOnly = inputValue.replace(/\D/g, ""); // Remove caracteres não numéricos
    const formatted = digitsOnly.slice(0, 3); // Limita a 3 dígitos

    setDadosCartao({ ...dadosCartao, cvv: formatted });
  };

  const handleInputValidateChange = (event) => {
    const inputValue = event;
    const digitsOnly = inputValue.replace(/\D/g, ""); // Remove caracteres não numéricos
    const formatted = digitsOnly.slice(0, 4); // Limita a 4 caracteres

    let formattedWithSlash = "";
    if (formatted.length > 2) {
      formattedWithSlash = `${formatted.slice(0, 2)}/${formatted.slice(2)}`;
    } else {
      formattedWithSlash = formatted;
    }

    setDadosCartao({ ...dadosCartao, validade: formattedWithSlash });
  };

  const cleanData = () => {
    setDadosUsuario({
      confirma_senha: "",
      data_nascimento: "",
      ddd: "",
      email: "",
      confirma_email: "",
      reemail: "",
      primeiro_nome: "",
      senha: "",
      sobrenome: "",
      telefone: "",
      documento: "",
      rg: "",
      nacionalidade: "",
      estado_civil: "",
      profissao: "",
      cep: "",
      endereco: "",
      numero: 0,
      doc_frente: "",
      doc_verso: "",
      selfie: "",
      vendedor: "",
      data_contratacao: "",
      files: [],
    });
    setModalAgregado(false);
    setModalAgregadoSelecionado(0);
  };

  const submitCadastros = () => {
    setSpinner(true);
    var body = {};
    body.usuario = dadosUsuario;
    body.plano_id = planoSelecionados.id;
    body.preco_final = parseFloat(planoSelecionados.preco.toFixed(2));
    body.preco_seguro = seguroSelecionado.precoSeguro;
    body.descricao_seguro = seguroSelecionado.descricaoSeguro;
    body.vendedor = vendedor ? vendedor.value : null;
    console.log("BODY", body);

    SaveAssinatura(body)
      .then((response) => {
        console.log("RESPOSTA OK", response.data.data.id);
        if (response.status === 201) {
          setNumeroAssinatura(response.data.data.id);
          setSpinner(false);
          setStep(3);
          window.scrollTo(0, 0);
          cleanData();
        } else {
          toast.error(response.response.data.msg);
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log("perereca ERRO", error);
      });
    //setSpinner(false);
    //setStep(3);
    //retornar topo da pagina
    //window.scrollTo(0, 0);
  };

  const listaArquivos = () => {
    return (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome do Arquivo</th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            {dadosUsuario.files.map((item) => (
              <>
                <tr>
                  <td>{item.name}</td>
                  <td>
                    <a href={item.url} target="_blank">
                      <Trash2
                        onClick={() =>
                          removeFile(dadosUsuario.files.indexOf(item))
                        }
                        size={20}
                      />
                    </a>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const listaArquivosDependentes = () => {
    return (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome do Arquivo</th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Ação
              </th>
            </tr>
          </thead>
          <tbody>
            {dependentes.files.map((item) => (
              <>
                <tr>
                  <td>{item.name}</td>
                  <td>
                    <a href={item.url} target="_blank">
                      <Trash2
                        onClick={() =>
                          removeFileDependentes(dependentes.files.indexOf(item))
                        }
                        size={20}
                      />
                    </a>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const getCEP = (cep) => {
    if (cep.length >= 8) {
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          setDadosUsuario({
            ...dadosUsuario,
            endereco: response.data.logradouro,
            bairro: response.data.bairro,
            cidade: response.data.localidade,
            estado: response.data.uf,
            cep: cep,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setDadosUsuario({ ...dadosUsuario, cep: cep });
    }
  };

  const DependentesList = () => {
    return (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {dependentes.map((item) => (
              <>
                <tr>
                  <td>
                    {item.primeiro_nome} {item.sobrenome}
                  </td>
                  <td>{item.tipo_dependente}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const mascaraCPF = (documento) => {
    console.log("DOCUMENTO", documento);
    if (checado === false) {
      if (documento.length <= 14) {
        if (documento.length === 3 || documento.length === 7) {
          setDadosUsuario({ ...dadosUsuario, documento: documento + "." });
        } else if (documento.length === 11) {
          setDadosUsuario({ ...dadosUsuario, documento: documento + "-" });
        } else {
          setDadosUsuario({ ...dadosUsuario, documento: documento });
        }
      }
    } else {
      if (documento.length <= 18) {
        if (documento.length === 2 || documento.length === 6) {
          setDadosUsuario({ ...dadosUsuario, documento: documento + "." });
        } else if (documento.length === 10) {
          setDadosUsuario({ ...dadosUsuario, documento: documento + "/" });
        } else if (documento.length === 15) {
          setDadosUsuario({ ...dadosUsuario, documento: documento + "-" });
        } else {
          setDadosUsuario({ ...dadosUsuario, documento: documento });
        }
      }
    }
  };

  const FileToBase64 = (e, tipo) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      if (tipo === "frente") {
        setDadosUsuario({ ...dadosUsuario, doc_frente: reader.result });
      } else if (tipo === "fundo") {
        setDadosUsuario({ ...dadosUsuario, doc_verso: reader.result });
      } else if (tipo === "selfie") {
        setDadosUsuario({ ...dadosUsuario, selfie: reader.result });
      }
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const FileToBase64Dependentes = (e, tipo, index) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      if (tipo === "frente") {
        setDependentes({ ...dependentes, doc_frente: reader.result });
      } else if (tipo === "fundo") {
        setDependentes({ ...dependentes, doc_verso: reader.result });
      } else if (tipo === "selfie") {
        setDependentes({ ...dependentes, selfie: reader.result });
      }
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  return (
    <>
      <Modal
        show={modalAgregado}
        size="lg"
        onHide={() => setModalAgregado(false)}
        centered
      >
        <ModalBody>
          <Row>
            <Col lg="12" style={{ textAlign: "center" }}>
              <h4>Cadastro de Agragados</h4>
              <Row>
                <Col lg="1"></Col>
                <Col lg="10">
                  <Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: "left" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <Label
                              style={{
                                fontWeight: "bold",
                                fontSize: "11px",
                              }}
                            >
                              Selfie
                            </Label>
                            <div
                              style={{
                                display: dependentes.selfie !== "" ? "block" : "none",
                                width: "100%",
                                height: "200px",
                                border: "1px solid #dfdfdf",
                                borderRadius: "5px",
                                backgroundImage: `url(${dependentes.selfie})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            <br />
                            <Input
                              type="file"
                              accept="image/*"
                              name="selfie"
                              id="selfie"
                              onChange={(e) =>
                                FileToBase64Dependentes(
                                  e.target.files[0],
                                  "selfie",
                                  1
                                )
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Nome do Dependente{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="dependente"
                        id="dependente"
                        value={dependentes.primeiro_nome}
                        onChange={(e) =>
                          setDependentes({
                            ...dependentes,
                            primeiro_nome: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Sobrenome <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="titular"
                        id="titular"
                        value={dependentes.sobrenome}
                        onChange={(e) =>
                          setDependentes({
                            ...dependentes,
                            sobrenome: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Data de Nascimento{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="date"
                        name="titular"
                        id="titular"
                        value={dependentes.data_nascimento}
                        onChange={(e) =>
                          setDependentes({
                            ...dependentes,
                            data_nascimento: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <br />
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Idade
                      </Label>
                      <Input
                        type="text"
                        name="parentesco"
                        id="parentesco"
                        value={
                          dependentes.data_nascimento !== ""
                            ? idadeAtual(dependentes.data_nascimento)
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        CPF <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="titular"
                        id="titular"
                        value={dependentes.documento}
                        onChange={(e) => {
                          mascaraCPF(e.target.value);
                          setDependentes({
                            ...dependentes,
                            documento: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        RG
                      </Label>
                      <Input
                        type="text"
                        name="titular"
                        id="titular"
                        value={dependentes.rg}
                        onChange={(e) => {
                          setDependentes({
                            ...dependentes,
                            rg: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" >
                    <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleFileDropDependente(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone">
                                <p>
                                  Arraste e solte a foto aqui, ou clique para
                                  selecionar
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {listaArquivosDependentes()}
                    </Col>
                  </Row>
                </Col>
                <Col lg="1"></Col>
              </Row>
              <br />
              <hr />
              <br />
              <Button
                className="btstep1"
                onClick={() => onSaveAgregado()}
                style={{ width: "250px" }}
              >
                <Save size={20} /> &nbsp; Salvar Agregado
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                className="btstep1"
                onClick={() => setModalAgregado(false)}
                style={{
                  width: "250px",
                  backgroundColor: "grey",
                  color: "#fff",
                }}
              >
                <X size={20} /> &nbsp; Cancelar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Row>
        <Col lg="2"></Col>
        <Col lg="8">
          <Stepper step={step} setStep={setStep}></Stepper>
        </Col>
        <Col lg="2"></Col>
      </Row>
      <Row>
        <div
          className="step1"
          style={{
            display: step === 0 ? "flow-root" : "none",
            marginTop: "20px",
            marginBottom: "55vh",
          }}
        >
          <h4 style={{ textAlign: "center" }}>Selecione um Plano</h4>
          <br />
          <Row>
            <Col lg="3"></Col>
            <Col lg="6">
              <div className="step1bt">
                {planos &&
                  planos.map((plano) => (
                    <Button
                      className="btstep1"
                      style={{
                        width: "47%",
                        margin: "5px",
                      }}
                      onClick={() => {
                        setStep(1);
                        window.scrollTo(0, 0);
                        setPlanoSelecionados(plano);
                      }}
                    >
                      {plano.descricao}
                    </Button>
                  ))}
              </div>
            </Col>
            <Col lg="3"></Col>
          </Row>
          <br />
        </div>
        <div
          className="step2"
          style={{ display: step === 1 ? "flow-root" : "none" }}
        >
          <Row>
            <Col lg="3"></Col>
            <Col lg="6">
              <h4 style={{ textAlign: "center", color: "#000" }}>
                Cadastro responsável pela assinatura
              </h4>
              <br />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                <label>
                  <input
                    type="radio"
                    value="PF"
                    checked={checado === false}
                    onChange={() => setChecado(false)}
                  />
                  &nbsp;Pessoa Física
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="radio"
                    value="PJ"
                    checked={checado === true}
                    onChange={() => setChecado(true)}
                  />
                  &nbsp;Pessoa Jurídica
                </label>
              </div>
              <br />
              <Row>
                <Col lg="12" style={{ textAlign: "left" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "50%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        {checado === false ? (
                          <>
                            Selfie <span style={{ color: "red" }}>*</span>
                          </>
                        ) : (
                          <>
                            Logo Empresa <span style={{ color: "red" }}>*</span>
                          </>
                        )}
                      </Label>
                      <div
                        style={{
                          display:
                            dadosUsuario.selfie !== "" ? "block" : "none",
                          width: "100%",
                          height: "200px",
                          border: "1px solid #dfdfdf",
                          borderRadius: "5px",
                          backgroundImage: `url(${dadosUsuario.selfie})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      <br />
                      <Input
                        type="file"
                        name="selfie"
                        id="selfie"
                        onChange={(e) =>
                          FileToBase64(e.target.files[0], "selfie")
                        }
                        accept="image/*"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Data de Contratação{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="date"
                        name="data_contratacao"
                        id="data_contratacao"
                        value={dadosUsuario.data_contratacao}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            data_contratacao: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        {checado === false ? (
                          <>
                            Nome <span style={{ color: "red" }}>*</span>
                          </>
                        ) : (
                          <>
                            Nome Fantasia{" "}
                            <span style={{ color: "red" }}>*</span>
                          </>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="nome"
                        id="nome"
                        value={dadosUsuario.primeiro_nome}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            primeiro_nome: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        {checado === false ? (
                          <>
                            Sobrenome <span style={{ color: "red" }}>*</span>
                          </>
                        ) : (
                          <>
                            Razão Social <span style={{ color: "red" }}>*</span>
                          </>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="sobrenome"
                        id="sobrenome"
                        value={dadosUsuario.sobrenome}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            sobrenome: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        {checado === false ? (
                          <>
                            CPF <span style={{ color: "red" }}>*</span>
                          </>
                        ) : (
                          <>
                            CNPJ <span style={{ color: "red" }}>*</span>
                          </>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="cpf"
                        id="cpf"
                        value={dadosUsuario.documento}
                        onChange={(e) => mascaraCPF(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        {checado === false ? (
                          <>
                            Data de Nascimento{" "}
                            <span style={{ color: "red" }}>*</span>
                          </>
                        ) : (
                          <>
                            Data de Fundação{" "}
                            <span style={{ color: "red" }}>*</span>
                          </>
                        )}
                      </Label>
                      <Input
                        type="date"
                        name="nascimento"
                        id="nascimento"
                        value={dadosUsuario.data_nascimento}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            data_nascimento: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        CEP <span style={{ color: "red" }}>*</span>
                      </Label>

                      <Input
                        type="text"
                        name="cep"
                        id="cep"
                        value={dadosUsuario.cep}
                        onChange={(e) => getCEP(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Endereço <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="rua"
                        id="rua"
                        value={dadosUsuario.endereco}
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Bairro <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="bairro"
                        id="bairro"
                        value={dadosUsuario.bairro}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            bairro: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Número <span style={{ color: "red" }}>*</span>
                      </Label>

                      <Input
                        type="text"
                        name="numero"
                        id="numero"
                        value={dadosUsuario.numero}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            numero: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="8" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Complemento
                      </Label>

                      <Input
                        type="text"
                        name="titular"
                        id="titular"
                        value={dadosUsuario.complemento}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            complemento: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="9" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Cidade <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="cidade"
                        id="cidade"
                        value={dadosUsuario.cidade}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            cidade: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="3" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Estado <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="estado"
                        id="estado"
                        value={dadosUsuario.estado}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            estado: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        DDD <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="number"
                        name="ddd"
                        id="ddd"
                        value={dadosUsuario.ddd}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            ddd: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="8" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Telefone <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="telefone"
                        id="telefone"
                        value={dadosUsuario.telefone}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            telefone: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        E-mail
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        value={dadosUsuario.email}
                        onChange={(e) =>
                          setDadosUsuario({
                            ...dadosUsuario,
                            email: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col lg="6" style={{ textAlign: "left" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "11px" }}>
                        Vendedor
                      </Label>
                      <SelectAsyncVendedor
                        defaultValue={vendedor.nome}
                        className='select-credenciado'
                        id={vendedor.id}
                        handleOnChangeSelect={
                          (data) => data ? setVendedor(data) : setVendedor("")
                          //setVendedor(data)
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ textAlign: "left" }}>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleFileDrop(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone">
                                <p>
                                  Arraste e solte a foto aqui, ou clique para
                                  selecionar
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {listaArquivos()}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg="3"></Col>
          </Row>
          <br />
          <hr />
          <br />
          <h4
            style={{
              textAlign: "center",
              color: "#000",
              display: dependentes.length > 0 ? "block" : "none",
            }}
          >
            Cadastro de Dependentes:
          </h4>

          <br />

          <Row>
            <Col lg="12" style={{ textAlign: "center" }}>
              {spinner ? (
                <Button className="btstep1" style={{ width: "200px" }} disabled>
                  <Spinner animation="border" variant="primary" />
                </Button>
              ) : (
                <Button
                  onClick={
                    () => submitCadastros()
                    // {setStep(3)
                    // window.scrollTo(0, 0)}
                  }
                  className="btstep1"
                  style={{ width: "200px" }}
                >
                  Salvar Assinatura
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <br />
        </div>
        <div
          className="step3"
          style={{ display: step === 2 ? "flow-root" : "none" }}
        ></div>
        <div style={{ display: step === 3 ? "block" : "none" }}>
          <Row>
            <Col
              lg="12"
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4 style={{ textAlign: "center", color: "#000" }}>Agregados</h4>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="12" style={{ textAlign: "center" }}>
              <Button
                className="btstep1"
                onClick={() => setModalAgregado(true)}
                style={{ width: "350px" }}
              >
                <UserPlus size={20} /> &nbsp; Adicionar novo agregado
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px", textAlign: "center" }}>
            {listaResultAgregados.length > 0 ? (
              <TabelaAgregados />
            ) : (
              <h5>Não há agregados cadastrados</h5>
            )}
          </Row>
          <br />

          <br />
          <br />
        </div>
        <div
          style={{
            display: step === 4 ? "block" : "none",
            marginTop: "20px",
            marginBottom: "55vh",
          }}
        >
          <Row>
            <Col lg="3"></Col>
            <Col
              lg="6"
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                <Row>
                  <Col lg="12">
                    <h4 style={{ textAlign: "center", color: "#000" }}>
                      Pagamento:
                    </h4>
                  </Col>
                </Row>
                <p />
                <h6 style={{ textAlign: "center", color: "gray" }}>
                  Plano: {planoSelecionados.descricao}
                </h6>
                <h6 style={{ textAlign: "center", color: "gray" }}>
                  Total a pagar: R${" "}
                  {planoSelecionados.preco != null
                    ? planoSelecionados.preco.toFixed(2).replace(".", ",")
                    : "0,00"}
                </h6>
                <p />
                <Row>
                  <Col lg="12">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <Input
                        onChange={(e) => handleInputChange(e.target.value)}
                        type="text"
                        name="text"
                        id="numerocartao"
                        placeholder="Número do Cartão"
                        value={dadosCartao.numero}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          position: "relative",
                          right: "3rem",
                          height: "30px",
                          marginTop: "3px",
                        }}
                      >
                        {bandeira(dadosCartao.numero)}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="12">
                    <Input
                      onChange={(e) =>
                        handleInputValidateChange(e.target.value)
                      }
                      value={dadosCartao.validade}
                      type="text"
                      name="text"
                      id="vvalidade"
                      placeholder="MM/AA"
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="12">
                    <Input
                      onChange={(e) => handleInputChangeCVV(e.target.value)}
                      value={dadosCartao.cvv}
                      type="number"
                      name="text"
                      id="cvv"
                      placeholder="CVV"
                      maxLength="3"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="3"></Col>
          </Row>
          <br />
          <Row>
            <Col lg="12" style={{ textAlign: "center" }}>
              {spinner ? (
                <Button className="btstep1" style={{ width: "200px" }} disabled>
                  <Spinner animation="border" variant="primary" />
                </Button>
              ) : (
                <Button
                  onClick={() => Pagar()}
                  className="btstep1"
                  style={{ width: "200px" }}
                >
                  Pagar
                </Button>
              )}
            </Col>
          </Row>
          <br />
          <br />
        </div>
        <div style={{ display: step === 5 ? "block" : "none" }}>
          <Row>
            <Col lg="3"></Col>
            <Col
              lg="6"
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                <Row>
                  <Col lg="12">
                    <h4 style={{ textAlign: "center", color: "#000" }}>
                      Resumo da Assinatura
                    </h4>
                  </Col>
                </Row>
                <p />
                <h6 style={{ textAlign: "center", color: "gray" }}>
                  Plano: {planoSelecionados.descricao}
                </h6>
                <h6 style={{ textAlign: "center", color: "gray" }}>
                  Valor mensal do contrato: R${" "}
                  {planoSelecionados.preco != null
                    ? planoSelecionados.preco.toFixed(2).replace(".", ",")
                    : "0,00"}
                </h6>
                <br />
                <span style={{ fontWeight: "bold" }}>Titular:</span>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {dadosUsuario.primeiro_nome} {dadosUsuario.sobrenome}
                </span>
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>Dependentes:</span>
                <br />
                {dependentes.length > 0 ? (
                  <DependentesList />
                ) : (
                  <>** Plano individual sem dependentes.</>
                )}

                <br />
                <br />
                <h5 style={{ textAlign: "center", color: "#b496db" }}>
                  Para acessar sua conta, clique em "Área do Cliente" no menu
                  superior.
                </h5>
              </div>
            </Col>
          </Row>
          <br />

          <br />
          <br />
        </div>
      </Row>
    </>
  );
};

export default AssinaturaADM;
