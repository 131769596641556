import React from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import AsyncSelectCidade from "../../../../components/asynselectcidade";
import AsyncSelectEstado from "../../../../components/asynselectestado";
import "react-datepicker/dist/react-datepicker.css";

const ModalVendedor = ({
  tipoModal,
  openModal,
  setOpenModal,
  dataForm,
  setDataForm,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={openModal}
      style={{ width: "600px", minWidth: "600px" }}
      className="modal-dialog-centered"
    >
      <ModalHeader>{tipoModal} Usuário</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="6">
            <Label>Primeiro Nome</Label>
            <Input
              type="text"
              value={dataForm.primeiro_nome}
              onChange={(e) =>
                setDataForm({ ...dataForm, primeiro_nome: e.target.value })
              }
            />
          </Col>
          <Col sm="6">
            <Label>Sobrenome</Label>
            <Input
              type="text"
              value={dataForm.sobrenome}
              onChange={(e) =>
                setDataForm({ ...dataForm, sobrenome: e.target.value })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm="2">
            <Label>DDD</Label>
            <Input
              type="number"
              maxLength={2}
              minLength={2}
              value={dataForm.ddd}
              onChange={(e) =>
                setDataForm({ ...dataForm, ddd: e.target.value })
              }
            />
          </Col>
          <Col sm="10">
            <Label>Telefone</Label>
            <Input
              type="number"
              maxLength={9}
              minLength={8}
              value={dataForm.telefone}
              onChange={(e) =>
                setDataForm({ ...dataForm, telefone: e.target.value })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm="6">
            <Label>Estado</Label>
            <AsyncSelectEstado
              defaultValue={{
                value: dataForm.estado.id,
                label: dataForm.estado.uf,
              }}
              className="select-credenciado"
              id=""
              handleOnChangeSelect={(data) =>
                data
                  ? setDataForm({
                      ...dataForm,
                      estado: {
                        id: data.value,
                        uf: data.label,
                      },
                      cidade: "", // Resetar a cidade quando o estado mudar
                    })
                  : setDataForm({
                      ...dataForm,
                      estado: {
                        id: 0,
                        uf: "",
                      },
                      cidade: "", // Resetar a cidade quando o estado mudar
                    })
              }
            />
          </Col>
          <Col sm="6">
            <Label>Cidade</Label>
            <AsyncSelectCidade
              value={{ label: dataForm.cidade, value: dataForm.cidade }}
              className="select-credenciado"
              id=""
              handleOnChangeSelect={(data) =>
                data
                  ? setDataForm({
                      ...dataForm,
                      cidade: data.label,
                    })
                  : setDataForm({
                      ...dataForm,
                      cidade: "",
                    })
              }
              estado={dataForm.estado.id}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onSubmit()}>
          Salvar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setOpenModal(false);
            setDataForm({
              primeiro_nome: "",
              sobrenome: "",
              ddd: 0,
              telefone: 0,
              cidade: "",
              estado: "",
            });
          }}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalVendedor;
