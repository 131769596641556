
const Stepper = ({ step, setStep }) => {
  const steps = [
    {
      title: "Step 1",
      content: "Step 1 Content",
    },
    {
      title: "Step 2",
      content: "Step 2 Content",
    },
    {
      title: "Step 3",
      content: "Step 3 Content",
    },
    
  ];

  return (
    <>
      <div className={"stepper"}>
        {steps.map((_, index) => (
          <>
            <div style={{marginLeft: "30px", marginRight: "30px"}}
              onClick={() => index <= step && step !== 0 && setStep(step - 1)}
              className={index <= step ? "circle active" : "circle"}  
            >
              {index + 1}
            </div>
            
          </>
        ))}
      </div>
    </>
  );
};

export default Stepper;
