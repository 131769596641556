import api from "../api";


export const Status = async (body) => {
        return api
          .get(`whatsapp/status`, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

        export const Conectar = async (body) => {
          return api
            .get(`whatsapp/start`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
              },
            })
            .then((response) => response)
            .catch((err) => err);
          }

          export const Desconectar = async (body) => {
            return api
              .get(`whatsapp/flush`, {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
              })
              .then((response) => response)
              .catch((err) => err);
            }

          export const GetQrcode = async (body) => {
            return api
              .get(`whatsapp/qrcode`, {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
              })
              .then((response) => response)
              .catch((err) => err);
            }
            