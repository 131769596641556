import { LogOut } from "react-feather";
import logo from "../../assets/images/logos/logo_header.png";
import { useNavigate } from "react-router-dom";
const HeaderADM = () => {

  const userData = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  return (
    <>
      <div className="MenuSmall">
        <div className="lineTriCollor">
          <div className="collor1"></div>
          <div className="collor2"></div>
          <div className="collor3"></div>
        </div>
        <div className="header">

          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
      <div className="MenuLarge">
        <div className="header">
          <div className="logo"
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
          }}
          >
            </div>
          
        </div>
        <div className="menu" style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '20px'
        }}>
          Bem vindo {userData.nome} | &nbsp;  <LogOut onClick={
            () => {
              navigate("/");
            }
          } className="buttonLogout"></LogOut>
        </div>
      </div>       
    </>
  );
};

export default HeaderADM;
