/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Paginador } from '../../service/vendedores';
import { de } from 'date-fns/locale';

const SelectAsyncVendedor = ({
  handleOnChangeSelect,
  placeholder,
  defaultValue,
  id,  
  className
}) => {

  const [filter, setFilter] = useState();
  const [noOptionsMessage, setNoOptionsMessage] = useState('')

  useEffect(() => {
    setFilter("");
  }, []);

  const onChange = option => {
    handleOnChangeSelect(option);
  }

  // Options - Select
  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    try {
      // if (!filter) {
      //   setNoOptionsMessage('Selecione um estado')
      //   return {
      //     options: []
      //   }
      // }
      
      const data = {
        nome: defaultValue ? defaultValue.value : "",
        limite: 10,
        pagina: 1
      }
      const response = await Paginador(data, 10, page);
      const options = response.data.data.map(item => {  
        console.log("TESTE", item);      
        return {
          value: item.id,
          label: item.primeiro_nome + ' ' + item.sobrenome          
        }
      })
      
      return {
        options,
        hasMore: response.data.currentPage < response.data.countPages,
        additional: {
          page: inputValue ? 1 : page + 1
        }
      }
    } catch (e) {
      return {
        options: []
      }
    }
  }
  console.log("DEFAULT", defaultValue);
  return (
    <div style={{ width: '100%'}}>
       <AsyncPaginate
        key={JSON.stringify(filter)}
        id={id}
        isClearable={true}
        className={className}
        classNamePrefix="select"
        value={defaultValue}
        loadingMessage={() => 'Carregando dados'}
        noOptionsMessage={() => noOptionsMessage || 'Nenhum registro encontrado'
      }
        loadOptions={loadOptions}
        onChange={onChange}
        isSearchable={true}
        placeholder='Selecione um Vendedor'
        additional={{
          page: 1
        }}
        styles={{
          input:provided => ({ ...provided, zIndex: 9999, color: '#000', width: '80px' }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      /> 
    </div>
  )
}

export default SelectAsyncVendedor;
