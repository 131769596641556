/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Edit2, Filter, Heart, Trash2, UserPlus } from "react-feather";
import { toast } from "react-toastify";
import {
    PaginadorDash,
    SavePlanos,
    EditPlano,
    RemovePlano,
    DesativaPlano
} from "../../../service/planos";

import { Card, CardBody, Row, Col, Button, Label, Input, Badge } from "reactstrap";
import SweetAlert2 from 'react-sweetalert2';
import ModalPlanos from "./modal";

const PlanosAdm = () => {

    const [swalProps, setSwalProps] = useState({});
    const [pagina, setPagina] = useState(1);
    const [tipoModal, setTipoModal] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [dataForm, setDataForm] = useState({
      descricao: "", 
      preco: 0,
      preco_agregado: 0, 
    });

    const [filter, setFilter] = useState([
      {
        pagina: pagina,
        limite: 10,
        descricao: null,
        ciclopay: null
      },
    ]);

    const [dados, setDados] = useState([
      {
        descricao: "", 
        preco: 0, 
        preco_agregado: 0,
      },
    ]);

    const [spinner, setSpinner] = useState(false);
    const [tooltipId, setTooltipId] = useState(null);

    const showTooltip = (id) => {
      setTooltipId(id);
    };
  
    const hideTooltip = () => {
      setTooltipId(null);
    };

    const handleOpenModal = (tipo, dados) => {
    if (tipo === "Criar") {
        setDataForm({
        descricao: "", 
        preco: 0,
        preco_agregado: 0,
        });
    } else  {
      setDataForm({
        descricao: dados.descricao,
        preco: dados.preco,
        preco_agregado: dados.preco_agregado,
        ciclopay_id: dados.ciclopay_id,
        id: dados.id
      });   
    }   
      setTipoModal(tipo);
      setOpenModal(true);
    };

    const DesativarPlano = (id) => {
      DesativaPlano(id).then((result) => {
        getPlanos();
        }
    ).catch((error) => {
        console.log(error);
        toast.error("Erro ao desativar o Plano!");
    }
    );
  };
  
    function TrashClick(id){
      console.log("ÇA PORRA DE ID", id);
      setSwalProps({
          show: true,
          title: 'Deseja remover o Plano?',
          text: "Não será possível reverter esta ação!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, remover!',
          cancelButtonText: 'Não, cancelar!',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          reverseButtons: true,
          focusCancel: true,
          focusConfirm: false,
  
          preConfirm: () => {
            RemoverPlano(id);
              setSwalProps({show: false});
          }
          
      }); 
    }
  
    const RemoverPlano = (id) => {

      RemovePlano(id).then((result) => {
        toast.success("Plano removido com sucesso!");
        getPlanos();
        }
    ).catch((error) => {
        console.log(error);
        toast.error("Erro ao remover o Plano!");
    }
    );
    };
  
    const getPlanos = () => {
      PaginadorDash(filter).then((result) => {
          console.log(result.data.data);
          setDados(result.data.data); 
      }).catch((error) => {
          console.log(error);
          toast.error("Erro ao carregar os dados!");
      });
    };
  
  
    useEffect(() => {
      getPlanos();
    }, []);
  
    const onSubmit = () => {
      setSpinner(true);
      if (!dataForm.id) { 
        SavePlanos(dataForm).then((result) => {
          if (result.response && result.response.status !== 201) { 
            toast.error("Erro ao cadastrar o Plano!");
            setSpinner(false);
            return;
          }

          setSpinner(false);

          if (dataForm.id) { 
            toast.success("Plano ajustado com sucesso!");
          } 

          toast.success("Plano cadastrado com sucesso!");
          setOpenModal(false);
          getPlanos();
        }).catch((error) => {
          toast.error("Erro ao cadastrar o Plano!");
        });

        return;
      }

      EditPlano(dataForm).then((result) => {
        if (result.response && result.response.status !== 204) { 
          toast.error("Erro ao editar o Plano!");
          setSpinner(false);
          return;
        }

        setSpinner(false);
        toast.success("Plano ajustado com sucesso!");
        setOpenModal(false);
        getPlanos();
      }).catch((error) => {
        toast.error("Erro ao cadastrar o Plano!");
      });
    };
  
    const columns = [
      {
        name: "Descrição",
        selector: "descricao",
        width: "30%",
        sortable: true,
        cell: (row) => (
          <>
              {row.descricao}
          </>
        ),
      },
      {
        name: "Preço",
        selector: "preco",
        sortable: true,
        cell: (row) => row.preco !== undefined ? <>R$ {(row.preco).toFixed(2)}</> : <>R$ 0,00</>
      },
      {
        name: "+ Agregado",
        selector: "preco_agregado",
        sortable: true,
        cell: (row) => row.preco_agregado !== undefined ? <>R$ {(row.preco_agregado).toFixed(2)}</> : <>R$ 0,00</>
      },
      {
        name: "Status",
        selector: "ativo",
        sortable: true,
        right: true,
        cell: (row) => (
          <>
            {row.ativo ? (
              <Badge 
              style={{cursor: "pointer"}}
              onClick={
                () => DesativarPlano(row.id)
              } color="success">Ativo</Badge>
              ) : (
                <Badge
                style={{cursor: "pointer"}}
                onClick={
                () => DesativarPlano(row.id)
              } color="danger">Inativo</Badge>
            )}
          </>
        ),
      },
      {
        name: "Ações",
        selector: "acoes",
        sortable: true,
        right: true,
        cell: (row) => (
          <>
          <div onMouseOver={() => showTooltip('editar-' + row.id)} onMouseLeave={() => hideTooltip()}
              style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
          }>
            <Edit2 style={{cursor: "pointer"}} size={18} onClick={() => handleOpenModal("Editar", row)} />
            {tooltipId === 'editar-' + row.id && (
              <div className="tooltip-adm"> Imprimir </div>
            )}
          </div>
          &nbsp;&nbsp;
          <div onMouseOver={() => showTooltip('remover-' + row.id)} onMouseLeave={() => hideTooltip()} 
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', }
          }>
            <Trash2 style={{cursor: "pointer"}} onClick={() => TrashClick(row.id)} size={18}></Trash2>
            {tooltipId === 'remover-' + row.id && (
              <div className="tooltip-adm">Remover</div>
            )}
          </div>
          </>
        ),
      },
    ];
  
    return (
      <>
        <ModalPlanos
          tipoModal={tipoModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
          dataForm={dataForm}
          setDataForm={setDataForm}
          onSubmit={onSubmit}
          spinner={spinner}
        />
        <SweetAlert2 {...swalProps} didClose={
          () => setSwalProps({show: false})
        }/>
        <br />
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col sm="2">
                  <h4>Planos</h4>
                </Col>
                <Col
                  sm="10"
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "right",
                  }}
                >
                  <Button
                    className="buttonSideFilter"
                    onClick={() => setOpenFilter(!openFilter)}
                  >
                    <Filter size={18} />
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    className="buttonSide"
                    onClick={() => handleOpenModal("Criar")}
                  >
                    <Heart size={18} />
                    &nbsp; Novo Plano
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
        <br />
        <div
          style={{
            display: openFilter ? "block" : "none",
            transitionTimingFunction: "ease-in-out",
            transitionDuration: "2s",
            transform: "translateY(-10px)",
          }}
        >
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="1">
                    <h5>Filtros</h5>
                  </Col>
                  <Col sm="11">
                    <Row>
                      <Col sm="3">
                        <Label>Plano</Label>
                        <Input 
                        type="text" 
                        value={filter.descricao}
                        onChange={(e) => setFilter({...filter, descricao: e.target.value})}
                        />
                      </Col>
                      <Col sm="3">
                        <Label>ID Ciclopay</Label>
                        <Input 
                        type="text" 
                        value={filter.ciclopay}
                        onChange={(e) => setFilter({...filter, ciclopay: parseInt(e.target.value)})}
                        />
                      </Col>
                    </Row>
                    <br />
                  <Row>
                    <Col sm="12">
                      <Button
                        size="sm"
                        onClick={() => getPlanos()}
                        className="buttonSide"
                        style={{ float: "right", width: "100px" }}
                      >
                        Filtrar
                      </Button>
                      </Col>
                  </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
  
        <Row>
          <Card>
            <CardBody>
              <Row>
                <DataTable
                  columns={columns}
                  data={dados}
                  pagination
                  highlightOnHover
                  responsive
                  striped
                  noDataComponent="Nenhum registro encontrado!"                 
                />
              </Row>
            </CardBody>
          </Card>
        </Row>
      </>
    );
  };
    export default PlanosAdm;