import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col, CardBody } from 'reactstrap';
import { Perfil } from '../../../service/users';
import { Paginador } from "../../../service/credenciadosPdf";
import { Download } from "react-feather";

const DashboardCliente = () => {

  const userData = localStorage.getItem('user');
  const [tabSelected, setTabSelected] = useState(1);
  const [dados, setDados] = useState();
  const [documentos, setDocumentos] = useState([
    {
      descricao: "",
      pdf: undefined
    },
  ]);

  const BuscarDados = async () => {
    // use stringfy to convert the localStorage data back to the original object
    console.log("ID", JSON.parse(userData).id);
    Perfil(JSON.parse(userData).id).then((response) => {
      console.log(response.data.data);
      setDados(response.data.data);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  const MoedaBRL = (valor) => {
    const valorFormatado = valor !== null ?  valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 0.00 
    return valorFormatado;
  }

  //Lista Dependenntes
  const ListaDependentes = () => {
    if (dados){
      const dependentes = [];
    if (dados.plano.dependentesAdulto.length > 0) {
      for(let i = 0; i < dados.plano.dependentesAdulto.length; i++){
        dependentes.push(dados.plano.dependentesAdulto[i]);
      }
    }
    if (dados.plano.dependentesCrianca.length > 0) {
      for(let i = 0; i < dados.plano.dependentesCrianca.length; i++){
        dependentes.push(dados.plano.dependentesCrianca[i]);
      }
    }
    console.log("DEPENNDETES: ", dependentes);
    return (
      <div>
        {dependentes.map((dependente, index) => (
          <div key={index}>
            <Row>
              <Col sm="6">
                <b>{dependente.nome}</b>
              </Col>
              <Col sm="6">
                <b>{dependente.tipo_dependente}</b>
              </Col>
            </Row>
            <br />
          </div>
        ))}
      </div>
    )
    }
  }
  

  // Apenas primeira letra de cada nome em maiúsculo
  const normalizaNome = (nome) => {
    if (nome !== null) {
      const minuscula = nome.toLowerCase();
      const nomes = minuscula.split(" ");
      const nomesNormalizados = [];
      for (let nome of nomes) {
        nomesNormalizados.push(nome[0].toUpperCase() + nome.substr(1));
      }
      return nomesNormalizados.join(" ");
    }
    else {
      return null;
    }

  }

  useEffect(() => {
    BuscarDados();
    getDocumentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocumentos = () => {

    const filter = {
      pagina: 1,
      limite: 10,
      descricao: null,
      ciclopay: null
    };

    Paginador(filter).then((result) => {
      console.log(result.data.content);
      setDocumentos(result.data.content);
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div>
      <Row>
        <Col sm="3"></Col>
        <Col sm="6" className='dashboard-cliente'>
          <br />
          <Row>
            <Col>
              <h5>Olá {dados ? normalizaNome(dados.usuario.nome) : null}</h5>
            </Col>
          </Row>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={tabSelected === 1}
                onClick={() => setTabSelected(1)}
                style={{ cursor: "pointer" }}
              >
                Meu Plano
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tabSelected === 2}
                onClick={() => setTabSelected(2)}
                style={{ cursor: "pointer" }}
              >
                Profissionais
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tabSelected === 3}
                onClick={() => setTabSelected(3)}
                style={{ cursor: "pointer" }}
              >
                Cancelamento
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="py-50" activeTab={tabSelected}>
            <TabPane tabId={1}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <h6 style={{ color: "#1990CF" }}>Informações do meu Plano</h6>
                        <hr style={{ borderColor: "#1990CF", borderWidth: "2px" }}></hr>
                      </Row>
                      <Row style={{ backgroundColor: "#f4f4f4" }}>
                        <Col sm="6">
                          Plano contratado<br />
                          <b>{normalizaNome(dados ? dados.plano.descricao : null)}</b>
                        </Col>
                        <Col sm="6">
                          Valor individual títular<br />
                          <b>{MoedaBRL(dados ? dados.plano.precoIndividual : 0)}</b>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm="6">
                          Quant. dependentes(Adultos)<br />
                          <b>{dados ? dados.plano.dependentesAdulto.length : 0} x {MoedaBRL(dados ? dados.plano.precoAdultos : 0)}</b>
                        </Col>
                        <Col sm="6">
                          Quant. dependentes(Crianças)<br />
                          <b>{dados ? dados.plano.dependentesCrianca.length : 0} x {MoedaBRL(dados ? dados.plano.precoCriancas : 0)}</b>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <span style={{
                          fontSize: "16px",
                        }} >Dependentes:</span>
                        <Col style={{
                          fontSize: "14px",
                        }} sm="6">
                          Nome:<br />
                        </Col>
                        <Col style={{
                          fontSize: "14px",
                        }} sm="6">
                          Tipo:<br />
                        </Col>
                        <ListaDependentes />
                      </Row>
                      <br />
                      <Row style={{ backgroundColor: "#f4f4f4" }}>
                        <Col sm="12">
                          Seguro contratado : <b>{dados ? dados.plano.descriaoSeguro : null}</b>
                        </Col>
                        <Col sm="12">
                          Valor do seguro contratado <b>{MoedaBRL(dados ? dados.plano.precoSeguro : 0)}</b>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm="4">
                          Valor TOTAL mensal<br />
                          <b>{dados ? MoedaBRL(dados.plano.precoFinal) : null}</b>
                        </Col>
                        <Col sm="4">
                          Status do Plano<br />
                          <b>{dados ? dados.plano.status : null}</b>
                        </Col>
                        <Col sm="4">
                          Cidade<br />
                          <b>{dados ? dados.usuario.cidade : null}</b>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br />
              <br />
            </TabPane>
          </TabContent>
          <TabContent className="py-50" activeTab={tabSelected}>
            <TabPane tabId={2}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h6 style={{ color: "#1990CF" }}>Procurando uma especialidade?</h6>
                      <hr style={{ borderColor: "#1990CF", borderWidth: "2px" }}></hr>
                      <div className="d-flex justify-content-center" style={{
                        alignItems: "center",
                        height: "100%",
                        width: "100%",

                      }}>
                        <Row>
                          <Col sm="6">
                          </Col>
                          <Col sm="6">
                            <h5>Encontre um profissional ou especialidade que você precisa</h5>
                            <p>Para baixar a lista de profissionais, clique no botão abaixo.</p>
                            <button style={{
                              backgroundColor: "#1CB1E2",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              marginTop: "-4px",
                            }} className="btn btn-info">Clique aqui</button>
                            <br></br>
                            {(documentos) ? (<>
                              <br></br>
                              <Row style={{ padding: "4px" }}>
                                <p style={{ fontSize: "14px", fontWeight: 'bold' }}>Conheça Nossos Médicos: </p>
                              </Row>
                              <div style={{ padding: "4px" }}>
                                <Row> {
                                  (documentos) ? documentos.map((documento, index) => (
                                    <div key={index}>
                                      <Col sm="12">
                                        <a href={documento.pdf} target="_blank" rel="noreferrer"
                                          style={{ textDecoration: "none", paddingTop: "1px", fontSize: "12px" }}>
                                          <p>{documento.descricao}</p>
                                        </a>
                                      </Col>
                                    </div>
                                  )) : null
                                } </Row>
                              </div>
                            </>) : null}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent className="py-50" activeTab={tabSelected}>
            <TabPane tabId={3}>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h6 style={{ color: "#1990CF" }}>Precisa cancelar ou alterar sue plano?</h6>
                      <hr style={{ borderColor: "#1990CF", borderWidth: "2px" }}></hr>
                      <Row>
                        <Col sm="6">
                        </Col>
                        <Col sm="6">
                          <h5>Cancelamento de Plano</h5>
                          <p>Para cancelar seu plano, ligue para:</p>
                          <p><b>0800 482 0800</b></p>
                          <p>ou</p>
                          <a
                            style={{ textDecoration: "none", marginTop: "-4px" }}
                            href="https://api.whatsapp.com/send?phone=‪5565996222017‬&text="
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button style={{
                              backgroundColor: "#1CB1E2",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              marginTop: "-4px",
                            }} className="btn btn-info">Clique aqui</button>
                          </a>
                          <p />
                          <p>para enviar iniciar um chat com um de nossos atendentes.</p>
                        </Col>
                      </Row>
                    </CardBody>

                  </Card>
                  <br />
                  <br />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
        <Col sm="3"></Col>
      </Row>
    </div>
  );

}

export default DashboardCliente;