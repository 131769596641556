import api from "../api";


export const PaginadorDash = async (body) => {
  console.log(body);
  return api
    .post(`plano/paginarAdmin`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const Paginador = async () => {
  const body = {
    pagina: 1,
    limite: 10,
    descricao: null
  }
  return api
    .post(`plano/paginar`, body, {
      headers: {
        "Content-Type": "application/json",

      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const SavePlanos = async (body) => {
  console.log(body)
  return api
    .post(`plano/criar`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const EditPlano = async (body) => {
  return api
    .put(`plano/atualizar`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const BuscaPlanoId = async (id) => {
  return api
    .get(`plano/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response)
    .catch((err) => err);
};


export const RemovePlano = async (id) => {
  return api
    .delete(`plano/remove/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

export const DesativaPlano = async (id) => {
  return api
    .get(`/plano/desativar/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
};




