import React, { useState } from "react";
import logo from "../../assets/images/logos/logo_header.png";
import iconface from "../../assets/images/icons/iconface.png";
import iconinsta from "../../assets/images/icons/iconinsta.png";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import { logar } from "../../service/login";
import { toast } from "react-toastify";
import { Eye, EyeOff, LogIn, Menu } from "react-feather";
import { set } from "date-fns";

const Header = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showLogin, setShowLogin] = React.useState(false);
  const [showPSW, setShowPSW] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    const body = {
      email: login,
      senha: password,
    };
    logar(body).then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user_data));
        if (response.data.user_data.role === "admin") {
          navigate("/adm/dashboardadm");
          setShowLogin(false);
          window.location.reload();
        } else if (response.data.user_data.role === "cliente") {
          console.log("cliente tipo", response.data.user_data.role);
          navigate("/cliente/dashboardcliente");
          setShowLogin(false);
          window.location.reload();
        }
      } else {
        toast.error("Usuário ou senha inválidos");
      }
    });
  };

  const openLogin = () => {
    setShowMenu(false);
    setShowLogin(!showLogin);
  }


  return (
    <>      
      <div className="MenuSmall">
        <br />
        <div>          
        <a
            style={{ textDecoration: "none", marginTop: "-4px" }}
            href="https://api.whatsapp.com/send?phone=‪5565996222017‬&text="
            target="_blank"
            rel="noreferrer"
          >  
          <span
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins",
            }}
          >
            Central de Relacionamento
          </span>
          <span
            style={{
              color: "#969696",
              fontSize: "12px",
              margin: "10px",
              fontFamily: "Poppins-Bold",
            }}
          >
            0800 482 0800
          </span>        
          </a>
        </div>
        <hr style={{width: "100%", margin: "0"}}></hr>
        <div className="header">
            <img src={logo} alt="logo" style={{
              width: "150px",
            }} /> <Menu size={28} color="#969696" onClick={() => setShowMenu(!showMenu)}></Menu>
        
        </div>
        
        <div className="menu" >
          <div className="buttons" style={{
          display: showMenu ? "" : "none"
        }}>
            <div className="buttonsmallmenu">
            <Link onClick={() => setShowMenu(false)} to="/" style={{textDecoration: "none"}}><div className="buttonLink">Inicio</div></Link>
            </div>
            <div className="buttonsmallmenu">
            <Link onClick={() => setShowMenu(false)} to="/especialidades" style={{textDecoration: "none"}}><div className="buttonLink">Especialidades</div></Link>
            </div>
            <div className="buttonsmallmenu">
            <Link onClick={() => setShowMenu(false)} to="/credenciados" style={{textDecoration: "none"}}><div className="buttonLink">Rede Credenciada</div></Link>
            </div>
            <div className="buttonsmallmenu">
            <Link onClick={() => setShowMenu(false)} to="/sobre" style={{textDecoration: "none"}}><div className="buttonLink">Sobre nós</div></Link>
            </div>
            <div className="buttonsmallmenu">
            <Link onClick={() => setShowMenu(false)} to="/blog" style={{textDecoration: "none"}}><div className="buttonLink">Blog</div></Link>
            </div>
            <div className="buttonsmallmenu">
            <a style={{textDecoration: "none"}}
        href="https://api.whatsapp.com/send?phone=‪5565996222017‬&text="
        target="_blank" rel="noreferrer">
            <div className="buttonLink">Agendar Consulta</div>
            </a>
            </div>
            <div className="buttonsmallmenu">
            <Link onClick={() => setShowMenu(false)} to="/# " style={{textDecoration: "none"}}><div className="buttonLink">Contratar </div></Link>
            </div>
            <div className="buttonsmallmenu">
            <Link onClick={() => openLogin()} to="/#" style={{textDecoration: "none"}}><div className="buttonLink">Acessar <LogIn /> </div></Link>
            </div>
            </div>
            </div>
            
      </div>
      <div
        className="Login"
        style={{
          display: showLogin ? "" : "none"
        }}
      >
        <Input
          type="text"
          placeholder="E-mail"
          className="LoginInput"
          style={{ width: "250px", margin: "10px" }}
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <Input
          type={showPSW ? "text" : "password"}
          placeholder="Senha"
          className="LoginInput"
          style={{ width: "250px", margin: "10px" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {showPSW ? (
          <Eye
            onClick={() => setShowPSW(!showPSW)}
            style={{ margin: "10px" }}
            color="#fff"
            size={20}
          ></Eye>
        ) : (
          <EyeOff
            onClick={() => setShowPSW(!showPSW)}
            style={{ margin: "10px" }}
            color="#fff"
            size={20}
          ></EyeOff>
        )}
        <Button
          onClick={() => handleLogin()}
          style={{ margin: "10px" }}
          block
          className="LoginButton"
        >
          Entrar
        </Button>
        <span style={{ color: "#fff", fontSize: "14px", margin: "10px" }}>
          |
        </span>
        <span
          onClick={() => {
            navigate("/senha");
            setShowLogin(false);
          }}
          style={{
            color: "#fff",
            fontSize: "14px",
            margin: "10px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          recuperar senha{" "}
        </span>
      </div>
    </>
  );
};

export default Header;
