/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Edit2, Filter, Trash2, UserPlus } from "react-feather";
import { toast } from "react-toastify";
import { Buffer } from 'buffer';
import {
  Status,
  Conectar,
  Desconectar,
  GetQrcode,
} from "../../../service/whatsapp";

import { Card, CardBody, Row, Col, Button, Label, Input, Badge } from "reactstrap";
import ModalUsuarios from "./modal";

import SweetAlert2 from 'react-sweetalert2';
import ModalWhatsapp from "./modal";

const Whatsapp = () => {
  const [swalProps, setSwalProps] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);
  const [data, setData] = useState([]);
  const [imageSrc, setImageSrc] = useState('');

  const ConectarSeção = async () => {
    const response = await Conectar();
    if (response.status === 200 || response.status === 201) {
      toast.success("Sessão Conectada com Sucesso!");
      StatusApi();
    } else {
      toast.error("Erro ao Conectar Sessão!");
    }
  }

  const GetQrcodeApi = async () => {
    const response = await GetQrcode();
    if (response.status === 200) {
      const byteArray = Object.values(response.data.qrcode).map(char => char.charCodeAt(0));
      console.log(byteArray);
  
      // Cria um Blob a partir do byteArray
      const blob = new Blob([new Uint8Array(byteArray)], { type: 'image/png' });
      // Usa FileReader para ler o Blob e gerar a string base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1]; // Remover o prefixo 'data:image/png;base64,'
        console.log(base64data); // Imprime a string base64 no console
      };
      reader.readAsDataURL(blob);
    }
  };

  const DesconectarSeção = async () => {
    const response = await Desconectar();
    if (response.status === 200) {
      toast.success("Sessão Desconectada com Sucesso!");
      StatusApi();
    } else {
      toast.error("Erro ao Desconectar Sessão!");
    }
  }

  const StatusApi = async () => {
    const response = await Status();
    if (response.status === 200) {
      console.log(response.data.status);
      setData(response.data);
      setSwalProps({
        show: true,
        title: 'Status',
        text: "API Whatsapp Online",
        icon: 'success',
      });
    } else {
      ConectarSeção();
      setSwalProps({
        show: true,
        title: 'Status',
        text: "API Whatsapp Offline",
        icon: 'error',
      });
    }
  };
  
  useEffect(() => {
    StatusApi();
  }
  , []);


  return (
    <>
      <ModalWhatsapp
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <SweetAlert2 {...swalProps} didClose={
        () => setSwalProps({show: false})
      }/>
      <br />
      <Row>
        <Card>
          <CardBody>
            <Row>
            <Col sm="2">
                <h4>Whatsapp</h4>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      <br />
      <Row>
        <Card>
          <CardBody>
            <Row>
            <h4>Status: {
              data.status === "inativo" ? <><Badge color="danger">Sessão Inativa</Badge></> : <><Badge color="success">Sessão Ativa</Badge></>
              }</h4>
              {
                data.status === "inativo" ? <Button style={{width: "250px"}} color="success" onClick={() => GetQrcodeApi()}>Conectar</Button> : <Button style={{width: "250px"}} color="danger" onClick={() => Desconectar()}>Desconectar</Button>
              }
            </Row>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};

export default Whatsapp;
