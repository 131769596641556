import api from "../api";


export const Paginador = async (body) => {
        return api
          .post(`usuario/paginarUsuarios`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const Perfil = async (id) => {
  const body = {
                id: id
              }
              console.log("DADOS ENVIADOS", body);
        return api
          .post(`usuario/perfil/`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const SaveUser = async (body) => {
        return api
          .post(`usuario/criar`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const UpdateUser = async (body) => {
        return api
          .put(`usuario/atualizar`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const GetUser = async (user) => {
        return api
          .get(`user/${user}`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const GetUPerfil = async (user) => {
  const body = {
                id: user
              }
        return api
          .post(`user/perfil`, body, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const RemoveUser = async (user) => {
        console.log(user);
        return api
          .post(`user/remove`, user,  {
            
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

        


