import api from "../api";


export const Paginador = async (body) => {
        return api
          .post(`vendedor/paginar`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const saveVendedor = async (body) => {
        return api
          .post (`vendedor/criar`, body, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

export const updateVendedor = async (body) => {
  return api
    .put(`vendedor/atualizar`, body, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response)
    .catch((err) => err);
  };  

export const RemoveVendedor = async (user) => {
        console.log(user.id);
        return api
          .delete(`vendedor/remover/${user.id}`,  {            
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
          })
          .then((response) => response)
          .catch((err) => err);
        };

        


