import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { PaginadorCidade } from '../../service/utils';

const SelectAsyncCidade = ({
  handleOnChangeSelect,
  placeholder,
  value,
  id,
  className,
  estado,
  tipo
}) => {
  const [filter, setFilter] = useState('');
  const [noOptionsMessage, setNoOptionsMessage] = useState('');

  useEffect(() => {
    setFilter('');
  }, [estado, tipo]);

  const onChange = option => {
    handleOnChangeSelect(option);
  }

  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    try {
      if (!estado) {
        setNoOptionsMessage('Selecione um estado primeiro');
        return {
          options: []
        }
      }
      const data = {
        estado_id: estado,
        nome: inputValue,
        limite: 30,
        pagina: 1
      }
      const response = await PaginadorCidade(data);
      const options = response.data.content.map(item => ({
        value: item.id,
        label: item.nome
      }));

      return {
        options,
        hasMore: response.data.currentPage < response.data.countPages,
        additional: {
          page: inputValue ? 1 : page + 1
        }
      }
    } catch (e) {
      return {
        options: []
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <AsyncPaginate
        id={id}
        isClearable={true}
        className={className}
        classNamePrefix="select"
        value={value}
        loadingMessage={() => 'Carregando dados'}
        noOptionsMessage={() => noOptionsMessage || 'Nenhum registro encontrado'}
        loadOptions={loadOptions}
        onChange={onChange}
        isSearchable={true}
        placeholder={placeholder || 'Cidade'}
        additional={{
          page: 1
        }}
        styles={{
          input: provided => ({ ...provided, zIndex: 9999, color: '#000', width: '80px' }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    </div>
  )
}

export default SelectAsyncCidade;
