import React, { useState } from "react";

const Franqueado = () => {

  return (
    <>
    </>
  );
};

export default Franqueado;
