import React, { useState } from 'react';
import { 
  Card, 
  Col, 
  Row, 
  Input, 
  Label, 
  Form,
  InputGroup,
  Button, 
  InputGroupText} from 'reactstrap';
import imageBgDesk from '../../../assets/images/bg/bg-login.png';
import { Eye, EyeOff } from 'react-feather';
import { logar } from '../../../service/login';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const HomePage = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [inputPassword, setInputPassword] = useState('');
    const [inputUser, setInputUser] = useState('');
    const [showLogin, setShowLogin] = useState(true);

    const navigate = useNavigate();
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const onSubmit = () => {
      logar({email: inputUser, senha: inputPassword})
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user_data));
          if (response.data.user_data.role === "admin") {
            navigate("/adm/dashboardadm");
            setShowLogin(false);
            window.location.reload();
          } else if (response.data.user_data.role === "cliente") {
            console.log("cliente tipo", response.data.user_data.role);
            navigate("/cliente/dashboardcliente");
            setShowLogin(false);
            window.location.reload();
          }
        } else {
          toast.error("Usuário ou senha inválidos");
        }
      }
      )
      .catch((error) => {
        console.log("error", error);
        toast.error("Usuário ou senha inválidos");
      });
    }

        
  return (
    <>
      <Row>
        <Col sm="12" lg="8">
          <div className='image-bg' style={{
            backgroundImage: `url(${imageBgDesk})`           
          }}></div>
        </Col>
        <Col className='bg-login' sm="12" lg="4">
          <Card>
            <div className='card-login'>
              <Row>
                <Col sm="12">
                  <div className='text-center'>Acesso ao Sistema Mais Vida</div>
                </Col>
                <Col sm="12">
                  <br />
                  <Form>
                  <Row>
                    <Col sm="12">
                      <Label>CPF ou E-mail:</Label>
                      <Input 
                        type="text" 
                        className="form-control" 
                        placeholder="CPF"
                        onChange={(e) => setInputUser(e.target.value)}
                        value={inputUser} 
                      />
                    </Col>
                    <Col sm="12">
                      <Label>Senha:</Label>
                      <InputGroup>
                        <Input 
                          type={showPassword ? "text" : "password"} 
                          className="form-control" 
                          placeholder="Senha" 
                          onChange={(e) => setInputPassword(e.target.value)}
                          value={inputPassword}
                        />
                        <InputGroupText style={{height: "38px"}} onClick={togglePasswordVisibility}>
                          {showPassword ? <Eye /> : <EyeOff />}
                        </InputGroupText>
                      </InputGroup>
                    </Col>
                  </Row>
                    <br />
                    <Row>
                      <Col sm="12">
                        <Button
                        onClick={() => onSubmit()}
                        className='button' 
                        block
                        ><strong>ENTRAR</strong></Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div style={{fontSize:  "12px", textAlign: "center", marginTop: "10px", textDecoration: "underline", cursor: "pointer"  
                        }}><span onClick={
                          () => {
                            navigate("/senha");
                          }
                        }>Primeiro acesso ou esqueceu a senha? Clique aqui</span></div>
                      </Col>
                    </Row>

                  </Form>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
