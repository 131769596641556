import React from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import AsyncSelectCidade from "../../../../components/asynselectcidade";
import AsyncSelectEstado from "../../../../components/asynselectestado";
import "react-datepicker/dist/react-datepicker.css";

const ModalWhatsapp = ({
  tipoModal,
  openModal,
  setOpenModal,
  dataForm,
  setDataForm,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={openModal}
      style={{ width: "600px", minWidth: "600px" }}
      className="modal-dialog-centered"
    >
      <ModalHeader>{tipoModal} Usuário</ModalHeader>
      
      <ModalFooter>
        <Button color="primary" onClick={() => onSubmit()}>
          Salvar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setOpenModal(false);
            setDataForm({
              primeiro_nome: "",
              sobrenome: "",
              ddd: 0,
              telefone: 0,
              cidade: "",
              estado: "",
            });
          }}
        >
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWhatsapp;
