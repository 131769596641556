import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import "react-datepicker/dist/react-datepicker.css";
import AssinaturaADM from "./cadastro";

const ModalAssinaturas = (
    {
        tipoModal,
        openModal,
        setOpenModal,
        dados,
        setDados,
        onSubmit
    }
) => {


    return (
        <>
        <Modal isOpen={openModal} style={{width: '90%', minWidth: '90%'}} className='modal-dialog-centered'>
            <ModalHeader>
                {tipoModal} Nova Assinatura
            </ModalHeader>
            <ModalBody>
                <AssinaturaADM openModal={openModal} setOpenModal={setOpenModal} />
            </ModalBody>
            <ModalFooter>
                
                <Button color='secondary' onClick={() => setOpenModal(false)}>Fechar</Button>
            </ModalFooter>
        </Modal>            
        </>
    )
}

export default ModalAssinaturas