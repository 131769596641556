import { Link } from 'react-router-dom';
import logoMaisVida from '../../assets/images/logos/logo_header.png';
import { Clipboard, FilePlus, Heart, Home, Phone, UserPlus, Watch } from 'react-feather';

const SideBarLeftADM = () => {

    const userData = JSON.parse(localStorage.getItem('user'));
    const userType = userData.role;

    console.log("role", userType);  

    const pages = [
        {
            name: <>
            <Home className='logo-bt' size={14} />&nbsp;Início
            </>,
            path: '/adm/dashboardadm'
        },
        {
            name: <>
            <FilePlus className='logo-bt' size={14} />&nbsp;Assinaturas
            </>,
            path: '/adm/assinaturas'
        },
        {
            name: <>
            <Heart className='logo-bt' size={14} />&nbsp;Planos
            </>,
            path: '/adm/planos'
        },
        /*
        {
            name: 'Banner',
            path: '/adm/banner'
        },        
        {
            name: 'Blog',
            path: '/adm/blog'
        },
        {
            name: 'Credenciados',
            path: '/adm/credenciados'
        },
        {
            name: 'Documentos',
            path: '/adm/documentos'
        },*/
        {
            name: <>
            <Clipboard className='logo-bt' size={14} />&nbsp;Vendedores
            </>,
            path: '/adm/vendedores'
        },
        {
            name: <>
            <UserPlus className='logo-bt' size={14} />&nbsp;Usuários
            </>,
            path: '/adm/usuarios'
        },
        {
            name: <>
            <Phone className='logo-bt' size={14} />&nbsp;Whatsapp
            </>,
            path: '/adm/watsapp'
        },

        // {
        //     name: 'Especialidades',
        //     path: '/adm/especialidades'
        // },
        // {
        //     name: 'Franqueados',
        //     path: '/adm/franqueados'
        // },
    ]

    return (
        // css code from cardMenu animated
        <div style={{height: "100%", alignItems: "flex-start"}}>
            <div className="sidearLeft" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{
                justifyContent: "flex-start",
                paddingTop: "3vh",
            }}>
                <img className='img-side-bar' src={logoMaisVida} alt="logo" width={180} />
                
                {pages.map((page, index) => 
                    {
                        return (
                            <Link className="bt-menu-adm" to={page.path} key={index} id="v-pills-home-tab" data-toggle="pill" role="tab"
                            
                    aria-controls="v-pills-home" aria-selected="true">{page.name}
                    </Link>
                        )
                    }
                )}
                    
                
            </div>
        </div>
    );
    }

    export default SideBarLeftADM;