import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <div>
        <Row className="footer-info">
          <Col sm="6">
            <span style={{ fontFamily: "Poppins-Bold" }}>
              © 2024 Mais Vida - Medicina e Saúde.
            </span>{" "}
            Todos os direitos reservados
          </Col>
          <Col sm="3"></Col>
          <Col sm="3">
            Desenvolvido por &nbsp;
            <a
              href="http://datassive.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ 
                fontFamily: "Poppins-Bold",
                textDecorationColor: "#fff",
                }}>DATASSIVE</span>
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
