import DataTable from "react-data-table-component";
import { Edit, Trash2 } from "react-feather";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";


const AgregadosUsuarios = (
    {
        dados,
        modalAgregados,
        setModalAgregados,
    }
) => {

    const columns = [
        {
            name: "Nome",
            selector: "nome",
            sortable: true,
        },
        {
            name: "Documento",
            selector: "documento",
            sortable: true,
        },
        {
            name: "Ações",
            cell: (row) => (
                <div>
                    <Edit 
                        size={20}
                        onClick={() => {
                            console.log(row);
                        }}
                    />
                    &nbsp;&nbsp;
                    <Trash2
                        size={20}
                        onClick={() => {
                            console.log(row);
                        }}
                    />                        
                </div>
            ),
        },
    ];

    return (
        <>
        <Modal
      isOpen={modalAgregados}
      className="modal-lg"
      >
        <ModalBody>
          <Row>
            <Col sm="12">
              <h4>Agregados</h4>
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={dados}
            highlightOnHover
            responsive
            striped
          />
          <Row>
            <Col sm="12">
              <Button
                className="button-form"
                onClick={() => setModalAgregados(false)}
              >
                Fechar
              </Button>
            </Col>
            </Row>
        </ModalBody>
      </Modal>
        </>
    );
    }

export default AgregadosUsuarios;