import React from "react";
const numero = require('numero-por-extenso');


const Contrato = ({
  dadosUsuario,
  dependentes,
  planoSelecionados,
  total,
  adicionais1,
  adicionais2,
  seguroSelecionado
}) => {

  console.log("Dados Usuário",dadosUsuario)
  console.log("Dependentes",dependentes)
  console.log("Planos Selecionados",planoSelecionados)
  console.log("Total", parseFloat(total.split(".")[0]))
  console.log("Adicionais 1",adicionais1)
  console.log("Adicionais 2",adicionais2)

  // console.log("Valor por extenso",numero(total.split(".")[0]))
  console.log("Valor por extenso",numero.porExtenso(parseFloat(total.split(".")[0]), numero.estilo.monetario))

    return (
        <></>
  );
}

    
   
export default Contrato;